import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image'

export const CardsCarousel = ({ slice }) => {
  return (
    <section data-component="cards-carousel" className="cards-carousel">
      <div className="container">
        <div className="row justify-content-center">
          {slice.items.map((item, i) =>
            <div className={`col-12 col-sm-6 col-lg-3 card-flip animate__animated animate__fadeInUp animate__faster animate__delay-${i}s`} key={`${i}-${item.image.alt}`}>
              {/* This empty container with className padding is used to keep aspect ratio of flex children */}
              <div className="padding"></div>
              <div className={`face face--front`}>
                <GatsbyImage
                  image={item?.image.gatsbyImageData}
                  alt={item?.image.alt ?? ""}
                  fadein={'false'}
                  loading={'eager'}
                />
              </div>
              <div className={`face face--back`}>
                <GatsbyImage
                  image={item?.backimage.gatsbyImageData}
                  alt={item?.backimage.alt ?? ""}
                  fadein={'false'}
                  loading={'eager'}
                />
                <div className="content">
                  <span>
                    <strong>{item?.additional_info.text ?? ""}</strong>.
                    <br/>
                    {item?.content.text ?? ""}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodyCardsCarousel on PrismicPageDataBodyCardsCarousel {
    primary {
      description {
        html
        richText
      }
      eyebrow_headline {
        html
        text
        richText
      }
      title {
        html
        richText
        text
      }
    }
    items {
      additional_info {
        html
        richText
        text
      }
      content {
        html
        richText
        text
      }
      image {
        alt
        url
        gatsbyImageData
      }
      backimage {
        alt
        url
        gatsbyImageData
      }
      title {
        html
        richText
        text
      }
    }
  }
`;
