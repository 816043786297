import * as React from "react";
import { Link } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";
import { linkResolver } from "../utils/linkResolver";
const { defaultLanguage } = require("../../prismic-configuration");

export const LanguageSwitcher = ({ activeDocMeta }) => {
  const alternateLangOptions = 
      <Link to={linkResolver(activeDocMeta.alternateLanguages[0])}>
        {activeDocMeta.lang === defaultLanguage ?
        <StaticImage
          src="../images/en-eu.svg"
          alt="sito inglese"
          height={20}
        />
        :<StaticImage
        src="../images/it-it.svg"
        alt="sito italiano"
        height={20}
      />}
      </Link>

  return (
    <>
      <div className="language-switcher mb-2 mt-2 text-start text-sm-end">{alternateLangOptions}</div>
    </>
  );
};
/* 

import * as React from 'react'
import { navigate } from 'gatsby'

import { linkResolver } from '../utils/linkResolver'

export const LanguageSwitcher = ({ activeDocMeta }) => {
  const currentLang = activeDocMeta.lang
  const currentLangOption = (
    <option value={currentLang}>{currentLang.slice(0, 2).toUpperCase()}</option>
  )

  const alternateLangOptions = activeDocMeta.alternateLanguages.map(
    (altLang, index) => (
      <option value={linkResolver(altLang)} key={`alt-lang-${index}`}>
        {altLang.lang.slice(0, 2).toUpperCase()}
      </option>
    ),
  )

  const handleLangChange = (event) => {
    navigate(event.target.value)
  }

  return (
    <li className="language-switcher">
      <select value={currentLang} onChange={handleLangChange}>
        {currentLangOption}
        {alternateLangOptions}
      </select>
    </li>
  )
}
 */