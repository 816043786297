import * as React from "react";
import { graphql } from "gatsby";
import { Map } from "../components/Map";
import { PrismicRichText } from "@prismicio/react";

export const Contacts = ({ slice }) => {
  const location = {
    address: slice.primary.address,
    lat: slice.primary.map.latitude,
    lng: slice.primary.map.longitude,
  };

  return (
    <section className="contact-section">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 align-self-center">
            <div className="contact-text-container">
              <h2 className="text-rose h2 mb-0 text-700">
                {slice.primary.address_label}
              </h2>
              <PrismicRichText field={slice.primary.address.richText} />{" "}
            </div>
            <div className="contact-text-container">
              <h2 className="text-rose h2 mb-0 text-700">
                {slice.primary.phone_label}
              </h2>
              <p>{slice.primary.phone}</p>
            </div>
            <div className="contact-text-container">
              <h2 className="text-rose h2 mb-0 text-700">
                {slice.primary.email_label}
              </h2>
              <p>{slice.primary.email}</p>
            </div>
          </div>
          <div className="col 12 col-md-8 align-self-center">
            <Map
              apiKey={slice.primary.api_map_key}
              location={location}
              zoomLevel={17}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodyContacts on PrismicPageDataBodyContacts {
    primary {
      api_map_key
      address {
        richText
      }
      phone
      email
      address_label
      phone_label
      email_label
      map {
        latitude
        longitude
      }
    }
  }
`;
