import GoogleMapReact from "google-map-react";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const LocationPin = ({ text }) => (
  <div className="pin" style={{
    position: 'absolute',
    transform: 'translate(-50%, -50%)'}
  }>
    <StaticImage
      src="../images/map-pin.svg"
      alt="pin mappa"
      placeholder="none"
    />
  </div>
);

export const Map = ({ location, zoomLevel, apiKey }) => (
  <div className="map">
    <div className="google-map">
      <GoogleMapReact
        options={{ mapId: "94800ad75966e7e8" }}
        bootstrapURLKeys={{ key: apiKey }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
      >
        <LocationPin
          lat={location.lat}
          lng={location.lng}
          text={location.address}
        />
      </GoogleMapReact>
    </div>
  </div>
);
