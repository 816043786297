import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

export const FullWidthImage = ({ slice }) => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col d-md-none">
            <GatsbyImage
              image={slice.primary.image_mobile?.gatsbyImageData}
              alt={slice.primary.image_mobile?.alt ?? ""}
            />
          </div>
          <div className="col d-none d-md-block">
            <GatsbyImage
              image={slice.primary.image_desktop?.gatsbyImageData}
              alt={slice.primary.image_desktop?.alt ?? ""}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodyFullWidthImage on PrismicPageDataBodyFullWidthImage {
    primary {
      image_mobile {
        gatsbyImageData
        alt
      }
      image_desktop {
        gatsbyImageData
        alt
      }
    }
  }
  fragment HomepageDataBodyFullWidthImage on PrismicHomepageDataBodyFullWidthImage {
    primary {
      image_mobile {
        gatsbyImageData
        alt
        copyright
      }
      image_desktop {
        gatsbyImageData
        alt
        copyright
      }

    }
  }
`;
