import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicLink, PrismicText } from "@prismicio/react";
import { LanguageSwitcher } from "./LanguageSwitcher";
const { defaultLanguage } = require("../../prismic-configuration")

export const Footer = ({ footer, activeDocMeta }) => {

  const currentLangLink = activeDocMeta.lang === defaultLanguage?"/":`/${activeDocMeta.lang}`
  const renderedFooterLinkA = footer
    ? footer.footer_link.slice(0, 2).map((menuLink, index) => (
        <div key={`footer-nav-a-${index}`} className="mb-2 mt-2 text-start">
          {menuLink.link.url ? (
            <PrismicLink href={menuLink.link.url}>
              <PrismicText field={menuLink.label.richText} />
            </PrismicLink>
          ) : (
            <PrismicText field={menuLink.label.richText} />
          )}
        </div>
      ))
    : null;
  const renderedFooterLinkB = footer
    ? footer.footer_link.slice(2, 4).map((menuLink, index) => (
        <div key={`footer-nav-b-${index}`} className="mb-2 mt-2 text-start">
          {menuLink.link.url ? (
            <PrismicLink href={menuLink.link.url}>
              <PrismicText field={menuLink.label.richText} />
            </PrismicLink>
          ) : (
            <PrismicText field={menuLink.label.richText} />
          )}
        </div>
      ))
    : null;
  const renderedFooterLinkC = footer
    ? footer.footer_link.slice(4, 6).map((menuLink, index) => (
        <div key={`footer-nav-c-${index}`} className="mb-2 mt-2 text-start">
          {menuLink.link.url ? (
            <PrismicLink href={menuLink.link.url}>
              <PrismicText field={menuLink.label.richText} />
            </PrismicLink>
          ) : (
            <PrismicText field={menuLink.label.richText} />
          )}
        </div>
      ))
    : null;

    return (
    <footer>
      <div className="container pt-4 pb-4">
        <div className="row">
          <div className="col-12 col-lg-2 pt-2 pb-2">
            <Link to={currentLangLink}>
              <GatsbyImage
                className="logo"
                alt={footer?.logo_footer?.alt}
                image={footer?.logo_footer?.gatsbyImageData}
              />
            </Link>
          </div>
          <div className="col">
            <div className="row">
              <div className="col-sm-3 col-12">
                {renderedFooterLinkA}
              </div>
              <div className="col-sm-3 col-12">
                {renderedFooterLinkB}
              </div>
              <div className="col-sm-4 col-12">
                {renderedFooterLinkC}
              </div>
              <div className="col-sm-2 col-12">
                <LanguageSwitcher activeDocMeta={activeDocMeta} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export const query = graphql`
  fragment FooterFragment on PrismicFooter {
    _previewable
    type
    lang
    data {
      logo_footer {
        alt
        copyright
        url
        gatsbyImageData
      }
      footer_link {
        label {
          richText
          html
          text
        }
        link {
          id
          url
        }
      }
    }
  }
`;
