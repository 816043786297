import { FullWidthImage } from './FullWidthImage'
import { InfoWithImage } from './InfoWithImage'
import { JuicerSocialWall } from "./JuicerSocialWall";
import { Contacts } from "./Contacts";
import { AboutUsSection } from './AboutUsSection'
import { VerticalSpacer } from "./VerticalSpacer";
import { CardsCarousel } from "./CardsCarousel";

export const components = {
  full_width_image: FullWidthImage,
  info_with_image: InfoWithImage,
  juicer_social_wall: JuicerSocialWall,
  contacts: Contacts,
  about_us_section: AboutUsSection,
  vertical_spacer: VerticalSpacer,
  cards_carousel:CardsCarousel
}
