import * as React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { SliceZone } from "@prismicio/react";

import { Layout } from "../components/Layout";
import { components } from "../slices";

const HomepageTemplate = ({ data }) => {
  if (!data) return null;

  const homepage = data.prismicHomepage || {};
  const topMenu = data.prismicTopMenu || {};
  const footer = data.prismicFooter || {};

  const { lang, type, url } = homepage || {};
  const alternateLanguages = homepage.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  const seo = { title: homepage.data.seo_title, description: homepage.data.seo_description };

  return (
    <Layout
      topMenu={topMenu.data}
      activeDocMeta={activeDoc}
      footer={footer.data}
      seo={ seo }
    >
      <SliceZone slices={homepage.data.body} components={components} />
    </Layout>
  );
};

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        seo_title
        seo_description
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...HomepageDataBodyFullWidthImage
          ...HomepageDataBodyInfoWithImage
          ...HomepageDataBodyVerticalSpacer
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
  }
`;

export default withPrismicPreview(HomepageTemplate);
