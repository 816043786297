import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { TopMenu } from './TopMenu'
import { Footer } from './Footer'
import { linkResolver } from "../utils/linkResolver";

export const Layout = ({ children, topMenu, footer, activeDocMeta, seo }) => {
  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <Helmet htmlAttributes={{ lang: activeDocMeta.lang }}>
        <meta charSet="utf-8" />
        <title>{seo.title ?? queryData.site.siteMetadata.title}</title>
        <meta
          name="description"
          content={seo.description ?? queryData.site.siteMetadata.description}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
        <link href="https://assets.juicer.io/embed.css" media="all" rel="stylesheet" type="text/css" />

        {activeDocMeta.alternateLanguages.map((altLang) => {
          return (
            <link rel="alternate" hrefLang={altLang.lang} href={linkResolver(altLang)} key={altLang.lang} />
          )
        })}
      </Helmet>
      <TopMenu topMenu={topMenu} activeDocMeta={activeDocMeta}/>
      <main>{children}</main>
      <Footer footer={footer} activeDocMeta={activeDocMeta}/>
    </>
  )
}
