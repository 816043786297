import * as React from "react";
import { graphql } from "gatsby";

export const VerticalSpacer = ({ slice }) => {
  return (
    <section>
      <div className={`container py-${slice.primary.mobile_pixel} py-sm-${slice.primary.desktop_pixel}`}>
        <div className="row">
          <div className="col">
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodyVerticalSpacer on PrismicPageDataBodyVerticalSpacer {
    primary {
      desktop_pixel
      mobile_pixel
    }
  }
  fragment HomepageDataBodyVerticalSpacer on PrismicHomepageDataBodyVerticalSpacer {
    primary {
      desktop_pixel
      mobile_pixel
    }
  }
`;
