import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

export const AboutUsSection = ({ slice }) => {

  const featuredImage = slice.primary.featured_image
  const alignment = slice.primary.choose_image_alignment==='right' ? "order-first order-md-last" : "order-last order-md-first"

  return (
    <section className={"about-us-section"}>
      <div className="container">
        <div className="row pt-4 pb-4">
          <div className={`col-12 col-md-5 align-items-center ${alignment}`}>
            <GatsbyImage
              image={featuredImage?.gatsbyImageData}
              alt={featuredImage?.alt ?? ""}
              className="about-us-image px-6"
            />
          </div>
          <div className="col-12 col-md-7 align-self-center font-light">
            <h3 className="font-bold pb-4 pt-2">{slice.primary.subtitle}</h3>
            <PrismicRichText field={slice.primary.description.richText} />
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodyAboutUsSection on PrismicPageDataBodyAboutUsSection {
    primary {
      subtitle
      description {
        richText
      }
      choose_image_alignment
      featured_image {
        gatsbyImageData
        alt
      }
    }
  }
`;
