import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

export const InfoWithImage = ({ slice }) => {
  const featuredImage = slice.primary.featured_image
  return (
    <section className="info-with-image">
      <div className="container">
        <div className="row pt-4 pb-4">
          <div className="col-12 col-sm-6 col-xl-4 offset-xl-2">
            <GatsbyImage
              image={featuredImage?.gatsbyImageData}
              alt={featuredImage?.alt}
              className="desktop"
            />
          </div>
          <div className="col-10 offset-1 col-sm-6 offset-sm-0 col-xl-4 align-self-center">
            <PrismicRichText field={slice.primary.text.richText} />
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyInfoWithImage on PrismicPageDataBodyInfoWithImage {
    primary {
      featured_image {
        gatsbyImageData
        alt
      }
      text {
        richText
      }
    }
  }
  fragment HomepageDataBodyInfoWithImage on PrismicHomepageDataBodyInfoWithImage {
    primary {
      featured_image {
        gatsbyImageData
        alt
      }
      text {
        richText
      }
    }
  }
`
