import * as React from "react";
import { Link, graphql } from "gatsby";
import { PrismicText } from "@prismicio/react";
import { StaticImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../images/logo.png"
const { defaultLanguage } = require("../../prismic-configuration")

export const TopMenu = ({ topMenu, activeDocMeta }) => {

  const currentLangLink = activeDocMeta.lang === defaultLanguage?"/":`/${activeDocMeta.lang}`
  const whatLocation = (props, url) => {
    const currentLocation = props.pathname;
    if (currentLocation === url) {
      return { className: "nav-link active" };
    }
  };

  const renderedMenuLinksLeft = topMenu
    ? topMenu.menu_links
        .slice(0, topMenu.menu_links.length / 2)
        .map((menuLink, index) => (
          <Link
            key={`top-nav-${index}`}
            to={menuLink.link.url}
            getProps={({ location }) =>
              whatLocation(location, menuLink.link.url)
            }
            className="nav-link"
          >
            <PrismicText field={menuLink.label.richText} />
          </Link>
        ))
    : null;
  const renderedMenuLinksRight = topMenu
    ? topMenu.menu_links
        .slice(topMenu.menu_links.length / 2)
        .map((menuLink, index) => (
          <Link
            key={`top-nav-${index}`}
            to={menuLink.link.url}
            getProps={({ location }) =>
              whatLocation(location, menuLink.link.url)
            }
            className="nav-link"
          >
            <PrismicText field={menuLink.label.richText} />
          </Link>
        ))
    : null;
  return (
    <>
      {["lg"].map((expand) => (
        <Navbar key={expand} expand={expand} className="p-0">
          <Container fluid className="justify-content-between px-4 py-4">
            <Offcanvas.Title
              id={`offcanvasNavbarLabel-expand-${expand}`}
              className="d-lg-none p-0 m-0"
            >
              <Navbar.Brand href={currentLangLink} className="p-0">
                <StaticImage
                  src="../images/logo.png"
                  alt="logo"
                  placeholder="none"
                  className="logo"
                />
              </Navbar.Brand>
            </Offcanvas.Title>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              className="custom-toggle-open border-0 p-0 m-0"
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="bg-brown text-ivory"
            >
              <Offcanvas.Header
                closeButton
                className="justify-content-end px-4 "
              >
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                ></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="menu">
                <Nav className="justify-content-center flex-grow-1 text-center">
                  {renderedMenuLinksLeft}
                  <Navbar.Brand href={currentLangLink}>
                    <img src={logo} alt="Canaglia Logo" className={"logo d-none d-lg-block"} />

                    {/*<StaticImage*/}
                    {/*  src="@images/logo.png"*/}
                    {/*  alt="logo"*/}
                    {/*  placeholder="none"*/}
                    {/*  className="logo d-none d-lg-block"*/}
                    {/*  quality={100}*/}
                    {/*/>*/}
                  </Navbar.Brand>
                  {renderedMenuLinksRight}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export const query = graphql`
  fragment TopMenuFragment on PrismicTopMenu {
    _previewable
    type
    lang
    data {
      menu_links {
        label {
          richText
          html
          text
        }
        link {
          id
          url
        }
      }
    }
  }
`;
